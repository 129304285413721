import { computed, defineComponent } from 'vue';
import ArrowRightIcon from '../../assets/svg/IconArrow.svg';
import ArrowLeftIcon from '../../assets/svg/IconArrowLeft.svg';
import IconArrowSecond from '../../assets/svg/IconArrowSecond.svg';
import IconBank from '../../assets/svg/bank.svg';
import IconCar from '../../assets/svg/IconCar.svg';
import IconCompany from '../../assets/svg/IconCompany.svg';
import ContactIcon from '../../assets/svg/IconContact.svg';
import IconCross from '../../assets/svg/IconCross.svg';
import EmailIcon from '../../assets/svg/IconEmail.svg';
import IconFacebook from '../../assets/svg/IconFacebook.svg';
import IconFilterList from '../../assets/svg/IconFilterList.svg';
import HeartIcon from '../../assets/svg/IconHeart.svg';
import IconInfo from '../../assets/svg/IconInfo.svg';
import IconLinkedIn from '../../assets/svg/IconLinkedIn.svg';
import LocationIcon from '../../assets/svg/IconLocation.svg';
import IconPlay from '../../assets/svg/IconPlay.svg';
import IconShare from '../../assets/svg/IconShare.svg';
import TelephoneIcon from '../../assets/svg/IconTelephone.svg';
import IconTravelMap from '../../assets/svg/IconTravelMap.svg';
import IconTravelPin from '../../assets/svg/IconTravelPin.svg';
import IconTwitter from '../../assets/svg/IconTwitter.svg';
import UserIcon from '../../assets/svg/IconUser.svg';
import IconXing from '../../assets/svg/xing.svg';
import TravelMapEarth from '../../assets/svg/TravelMapEarth.svg';
import Automotive from '../../assets/svg/automotive.svg';
import Commerce from '../../assets/svg/commerce.svg';
import Energy from '../../assets/svg/energy.svg';
import Exhibition from '../../assets/svg/exhibition.svg';
import Health from '../../assets/svg/health.svg';
import Insurance from '../../assets/svg/insurance.svg';
import Lottery from '../../assets/svg/lottery.svg';
import Manufactoring from '../../assets/svg/manufactoring.svg';
import Media from '../../assets/svg/media.svg';
import Public from '../../assets/svg/public.svg';
import Sience from '../../assets/svg/sience.svg';
import Sport from '../../assets/svg/sport.svg';
import Travel from '../../assets/svg/travel.svg';
const icons = {
    arrowLeft: ArrowLeftIcon,
    arrowRight: ArrowRightIcon,
    arrowSecond: IconArrowSecond,
    bank: IconBank,
    car: IconCar,
    company: IconCompany,
    contact: ContactIcon,
    cross: IconCross,
    email: EmailIcon,
    facebook: IconFacebook,
    heart: HeartIcon,
    info: IconInfo,
    linkedIn: IconLinkedIn,
    list: IconFilterList,
    location: LocationIcon,
    map: IconTravelMap,
    play: IconPlay,
    share: IconShare,
    telephone: TelephoneIcon,
    travelPin: IconTravelPin,
    twitter: IconTwitter,
    user: UserIcon,
    xing: IconXing,
    earth: TravelMapEarth,
    automotive: Automotive,
    commerce: Commerce,
    energy: Energy,
    exhibition: Exhibition,
    health: Health,
    insurance: Insurance,
    lottery: Lottery,
    manufactoring: Manufactoring,
    media: Media,
    public: Public,
    sience: Sience,
    sport: Sport,
    travel: Travel,
};
export default defineComponent({
    components: { ContactIcon },
    props: {
        name: {
            type: String,
            required: true,
            validator: (value) => Object.prototype.hasOwnProperty.call(icons, value),
        },
    },
    setup(props) {
        // @ts-ignore
        const iconComponent = computed(() => icons[props.name]);
        return { iconComponent };
    },
});
